<template>
  <div class="form-floating" v-if="controlType==='select'">
    <select class="form-select form-control" v-model="controlValue" :id="`floatingSelectGrid-${_uid}`"
      aria-label="Floating label select example" :disabled="disabled" @change="valueChange">
      <option v-for="(item, inx) in options" :key="inx" :value="item.value">{{ item.text }}</option>
    </select>
    <label :for="`floatingSelectGrid-${_uid}`">{{ label }}</label>
  </div>

  <div class="form-floating" v-else>
    <i class="fa-solid fa-calendar-day" v-if="controlType==='date'"></i>

    <input :type="controlType==='date' ? 'text' : controlType"
      class="form-control"
      :id="`floatinglname-${_uid}`"
      :class="{'is-invalid': isInvalid}"
      ref="datepicker"
      v-model="controlValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="valueChange"
      required/>

    <label :for="`floatinglname-${_uid}`">{{ label }}</label>
    <div class="invalid-feedback">{{ invalidFeedback }}</div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'payer-detail-form-control',
  props: {
    controlType: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  watch: {
    value() {
      this.controlValue = this.value;
    },
  },
  data() {
    return {
      controlValue: '',
      isInvalid: false,
      jqueryObject: null,
    };
  },
  mounted() {
    this.controlValue = this.value;

    if (this.controlType === 'date') {
      const vm = this;
      this.jqueryObject = $(this.$refs.datepicker);

      this.jqueryObject.datepicker({
        minDate: new Date('1900-01-01'),
        dayNamesMin: Object.values(this.$t('weekShortName')),
        monthNamesShort: Object.values(this.$t('monthName')),
        changeMonth: true,
        changeYear: true,
        dateFormat: 'yy-mm-dd',
        isRTL: true,
        showButtonPanel: true,
        prevText: '',
        nextText: '',
        yearRange: '1910:2023',
        onSelect: (date) => {
          vm.controlValue = date;
          vm.valueChange();
        },
        beforeShow() {
          $('#ui-datepicker-div').addClass('datepicker');
        },
      });
      // .on('focus', () => {
      //   $('.ui-datepicker').prepend(' בחר / י תאריך לידה ');
      // });
    }
  },
  methods: {
    valueChange() {
      this.isInvalid = !this.checkValid();
      this.$emit('change', this.controlValue);
    },
    checkValid() {
      if (this.controlType === 'email') {
        return this.controlValue.includes('@') && this.controlValue.includes('.');
      } else if (this.controlValue === 'mobile') {
        return this.controlValue.length > 3 && !Number.isNaN(+this.controlValue);
      } else {
        return this.controlValue.length > 3;
      }
    },
  },
};
</script>
